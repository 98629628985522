
// @ts-ignore
import colorTheme from '@petlove/webstore-mixins/nuxt/color-theme.mjs';
import { ref, defineComponent } from '@vue/composition-api';
import { useNamespacedState } from 'vuex-composition-helpers';

import { SSOState } from '@/store/sso/state';
import { WhitelabelState } from '@/store/whitelabel/state';

export default defineComponent({
  mixins: [colorTheme],
  setup() {
    const isPrivacyPage = ref(false);
    const { snackbar } = useNamespacedState<SSOState>('sso', ['snackbar']);
    const { store: infoWL } = useNamespacedState<WhitelabelState>(
      'whitelabel',
      ['store']
    );

    return { infoWL, snackbar, isPrivacyPage };
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.isPrivacyPage = this.$route.name === 'privacidade';
      }
    }
  }
});
