export const buildWhitelabelTheme = (store) => {
	if (!store || !store.color) return '';
	return `
	--primary: ${store.wlTheme.main};
	--primary-darkest: ${store.wlTheme.darkest};
	--primary-dark: ${store.wlTheme.dark};
	--primary-medium: ${store.wlTheme.medium};
	--primary-light: ${store.wlTheme.light};
	--primary-lightest: ${store.wlTheme.lightest};
	--secondary: ${store.color.secondary};
`;
};

export default {
  computed: {
    colorTheme() {
      return this.infoWL?.isWhitelabel ? buildWhitelabelTheme(this.infoWL) : null;
    }
  }
};
